import React from "react"

import { Helmet } from "react-helmet"
import seoProductsCategoriesUrls from "../static/seoProductsCategoriesUrls.json"
import faviconHalva from "../images/logo512.png"

const htmlAttributes = {
  lang: "ru",
}
const defTitle = "Бесплатная доставка карты Халва"
const defDescription =
  "Бесплатная доставка карты рассрочки Халва Совкомбанка. Одобрение онлайн. Заполните заявку - и уже завтра курьер доставит вам Халву!"
const author = "@sovcombank"

export type SEOProps = {
  title?: string
  description?: string
  titleH1?: string
  url?: string
  shortTitle?: string
  themeColor?: string
  favicon?: string
}

export function SEO({
  title = defTitle,
  description = defDescription,
  titleH1 = "",
  url = "",
  shortTitle = "",
  themeColor = "#ff4e50",
  favicon = faviconHalva,
}: SEOProps) {
  const meta = [
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: author,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "description",
      content: description,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "format-detection",
      content: "telephone=no",
    },
    {
      name: "theme-color",
      content: themeColor,
    },
  ]

  const link = [
    {
      rel: "icon",
      href: favicon,
      type: "image/png",
    },
  ]

  return (
    <>
      <Helmet htmlAttributes={htmlAttributes} title={title} meta={meta} link={link} />
      {process.env.GATSBY_PATH_PREFIX === "/apply/sbp" && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify([
              {
                "@context": "https://schema.org",
                "@type": "Corporation",
                name: "Совкомбанк",
                alternateName: "Sovcombank",
                url: "https://sovcombank.ru/",
                logo: "https://sovcombank.ru/logo.png",
                contactPoint: {
                  "@type": "ContactPoint",
                  telephone: "8 (800) 100-77-72",
                  contactType: "customer service",
                  contactOption: "TollFree",
                  areaServed: "RU",
                  availableLanguage: "Russian",
                },
              },
              {
                "@context": "https://schema.org",
                "@type": "BankOrCreditUnion",
                image: {
                  url: "https://sovcombank.ru/logo.png",
                  "@type": "ImageObject",
                },
                address: {
                  "@type": "PostalAddress",
                  addressLocality: "Москва",
                  addressCountry: "Россия",
                  telephone: "8 (800) 100-77-72",
                },
                contactPoint: {
                  "@type": "ContactPoint",
                  telephone: "8 (800) 100-77-72",
                  contactType: "Служба поддержки клиентов",
                  contactOption: "бесплатно для звонков по России",
                  areaServed: "RU",
                  availableLanguage: "Russian",
                },
              },
            ])}
          </script>
        </Helmet>
      )}
      {process.env.GATSBY_PATH_PREFIX === "/apply" && url.includes("nakopitelnye-scheta") && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BankOrCreditUnion",
              name: "Совкомбанк",
              image: "https://sovcombank.ru/logo.png",
              "@id": "https://sovcombank.ru",
              url: "https://sovcombank.ru/",
              telephone: "8(800)100-00-06",
              priceRange: "1 - 10 000 000",
              address: {
                "@type": "PostalAddress",
                streetAddress: "Краснопресненская набережная, 14 стр 1",
                addressLocality: "Москва",
                postalCode: "123100",
                addressCountry: "RU",
              },
              openingHoursSpecification: {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ],
                opens: "00:00",
                closes: "23:59",
              },
              sameAs: [
                "https://ru.wikipedia.org/wiki/Совкомбанк",
                "https://sovcombank.ru",
                "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
              ],
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Совкомбанк",
                  item: "https://sovcombank.ru/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "💰 Накопительные счета",
                  item: "https://sovcombank.ru/apply/nakopitelnye-scheta/",
                },
                { "@type": "ListItem", position: 3, name: `✔ ${shortTitle}`, item: `${url}` },
              ],
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              name: `${titleH1}`,
              image: "https://sovcombank.ru/logo.png",
              description: `${description}`,
              brand: { "@type": "Brand", name: "Совкомбанк" },
              offers: {
                "@type": "AggregateOffer",
                url: `${url}`,
                priceCurrency: "RUB",
                lowPrice: "1",
                highPrice: "10000000",
              },
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "9,8",
                bestRating: "10",
                worstRating: "1",
                ratingCount: "6512",
              },
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "QAPage",
              mainEntity: {
                "@type": "Question",
                name: "Преимущества накопительного счета в Совкомбанке",
                text: "Какие преимущества накопительного счета в Совкомбанке?",
                answerCount: 1,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "🔥 Пополнение в любое время, ✅ Проценты начисляются каждый месяц, 🔵 Гибкие сроки без ограничений",
                },
              },
            })}
          </script>
        </Helmet>
      )}
      {process.env.GATSBY_PATH_PREFIX === "/order" &&
        url.includes("credit-card") &&
        url.endsWith("/credit-card/") && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "BankOrCreditUnion",
                  name: "Halvacard",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  "@id": "https://halvacard.ru",
                  url: "https://halvacard.ru/",
                  telephone: "8 (800) 100-77-72",
                  priceRange: "10 000 - 500 000",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Краснопресненская набережная, 14 стр 1",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                  openingHoursSpecification: {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: [
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                    ],
                    opens: "00:00",
                    closes: "23:59",
                  },
                  sameAs: [
                    "https://ru.wikipedia.org/wiki/Совкомбанк",
                    "https://halvacard.ru",
                    "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                  ],
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org/",
                  "@type": "Product",
                  name: `${titleH1}`,
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  description: `${description}`,
                  brand: {
                    "@type": "Brand",
                    name: "Halvacard",
                  },
                  offers: {
                    "@type": "AggregateOffer",
                    url: `${url}`,
                    priceCurrency: "RUB",
                    lowPrice: "10000",
                    highPrice: "500000",
                  },
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: "4,9",
                    bestRating: "5",
                    worstRating: "1",
                    ratingCount: "9594",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "SaleEvent",
                  name: "📦 Бесплатная доставка",
                  description:
                    "Бесплатная доставка карты рассрочки Халва Совкомбанка. Одобрение онлайн. Заполните заявку - и уже завтра курьер доставит вам Халву!",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  startDate: "2023-01-01",
                  endDate: "2023-12-31",
                  eventStatus: "https://schema.org/EventScheduled",
                  eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                  location: {
                    "@type": "Place",
                    name: "Бесплатная доставка",
                    address: {
                      "@type": "PostalAddress",
                      streetAddress: "Москва",
                      addressLocality: "Москва",
                      postalCode: "123100",
                      addressCountry: "RU",
                    },
                  },
                  performer: {
                    "@type": "PerformingGroup",
                    name: "HalvaCard",
                  },
                  offers: {
                    "@type": "Offer",
                    name: "Бесплатная доставка",
                    price: "999",
                    priceCurrency: "RUB",
                    validFrom: "",
                    url: "https://halvacard.ru/order/credit-card/",
                    availability: "https://schema.org/InStock",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "SaleEvent",
                  name: "💳 До 10 месяцев рассрочки",
                  description:
                    "Получить карту Халва с рассрочкой 10 месяцев очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  startDate: "2023-01-01",
                  endDate: "2023-12-31",
                  eventStatus: "https://schema.org/EventScheduled",
                  eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                  location: {
                    "@type": "Place",
                    name: "До 10 месяцев рассрочки",
                    address: {
                      "@type": "PostalAddress",
                      streetAddress: "Москва",
                      addressLocality: "Москва",
                      postalCode: "123100",
                      addressCountry: "RU",
                    },
                  },
                  performer: {
                    "@type": "PerformingGroup",
                    name: "HalvaCard",
                  },
                  offers: {
                    "@type": "Offer",
                    name: "До 10 месяцев рассрочки",
                    price: "999",
                    priceCurrency: "RUB",
                    validFrom: "",
                    url: "https://halvacard.ru/order/credit-card/",
                    availability: "https://schema.org/InStock",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "SaleEvent",
                  name: "🪙 Кэшбэк до 10%",
                  description:
                    "Получить карту рассрочки Халва с кэшбэком до 10% очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  startDate: "2023-01-01",
                  endDate: "2023-12-31",
                  eventStatus: "https://schema.org/EventScheduled",
                  eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                  location: {
                    "@type": "Place",
                    name: "Кэшбэк до 10%",
                    address: {
                      "@type": "PostalAddress",
                      streetAddress: "Москва",
                      addressLocality: "Москва",
                      postalCode: "123100",
                      addressCountry: "RU",
                    },
                  },
                  performer: {
                    "@type": "PerformingGroup",
                    name: "HalvaCard",
                  },
                  offers: {
                    "@type": "Offer",
                    name: "Кэшбэк до 10%",
                    price: "999",
                    priceCurrency: "RUB",
                    validFrom: "",
                    url: "https://halvacard.ru/order/credit-card/",
                    availability: "https://schema.org/InStock",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  itemListElement: [
                    {
                      "@type": "ListItem",
                      position: 1,
                      name: "Halvacard",
                      item: "https://halvacard.ru/",
                    },
                    {
                      "@type": "ListItem",
                      position: 2,
                      name: "💳 Кредитные карты",
                      item: "https://halvacard.ru/order/credit-card/",
                    },
                  ],
                },
              ])}
            </script>
          </Helmet>
        )}
      {process.env.GATSBY_PATH_PREFIX === "/order" &&
        url.includes("credit-card") &&
        !url.endsWith("/credit-card/") && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "BankOrCreditUnion",
                  name: "Halvacard",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  "@id": "https://halvacard.ru",
                  url: "https://halvacard.ru/",
                  telephone: "8 (800) 100-77-72",
                  priceRange: "10 000 - 500 000",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Краснопресненская набережная, 14 стр 1",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                  openingHoursSpecification: {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: [
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                    ],
                    opens: "00:00",
                    closes: "23:59",
                  },
                  sameAs: [
                    "https://ru.wikipedia.org/wiki/Совкомбанк",
                    "https://halvacard.ru",
                    "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                  ],
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org/",
                  "@type": "Product",
                  name: `${titleH1}`,
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  description: `${description}`,
                  brand: {
                    "@type": "Brand",
                    name: "Halvacard",
                  },
                  offers: {
                    "@type": "AggregateOffer",
                    url: `${url}`,
                    priceCurrency: "RUB",
                    lowPrice: "10000",
                    highPrice: "500000",
                  },
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: "4,9",
                    bestRating: "5",
                    worstRating: "1",
                    ratingCount: "9594",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "SaleEvent",
                  name: "📦 Бесплатная доставка",
                  description:
                    "Бесплатная доставка карты рассрочки Халва Совкомбанка. Одобрение онлайн. Заполните заявку - и уже завтра курьер доставит вам Халву!",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  startDate: "2023-01-01",
                  endDate: "2023-12-31",
                  eventStatus: "https://schema.org/EventScheduled",
                  eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                  location: {
                    "@type": "Place",
                    name: "Бесплатная доставка",
                    address: {
                      "@type": "PostalAddress",
                      streetAddress: "Москва",
                      addressLocality: "Москва",
                      postalCode: "123100",
                      addressCountry: "RU",
                    },
                  },
                  performer: {
                    "@type": "PerformingGroup",
                    name: "HalvaCard",
                  },
                  offers: {
                    "@type": "Offer",
                    name: "Бесплатная доставка",
                    price: "999",
                    priceCurrency: "RUB",
                    validFrom: "",
                    url: "https://halvacard.ru/order/credit-card/",
                    availability: "https://schema.org/InStock",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "SaleEvent",
                  name: "💳 До 10 месяцев рассрочки",
                  description:
                    "Получить карту Халва с рассрочкой 10 месяцев очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  startDate: "2023-01-01",
                  endDate: "2023-12-31",
                  eventStatus: "https://schema.org/EventScheduled",
                  eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                  location: {
                    "@type": "Place",
                    name: "До 10 месяцев рассрочки",
                    address: {
                      "@type": "PostalAddress",
                      streetAddress: "Москва",
                      addressLocality: "Москва",
                      postalCode: "123100",
                      addressCountry: "RU",
                    },
                  },
                  performer: {
                    "@type": "PerformingGroup",
                    name: "HalvaCard",
                  },
                  offers: {
                    "@type": "Offer",
                    name: "До 10 месяцев рассрочки",
                    price: "999",
                    priceCurrency: "RUB",
                    validFrom: "",
                    url: "https://halvacard.ru/order/credit-card/",
                    availability: "https://schema.org/InStock",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "SaleEvent",
                  name: "🪙 Кэшбэк до 10%",
                  description:
                    "Получить карту рассрочки Халва с кэшбэком до 10% очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                  image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                  startDate: "2023-01-01",
                  endDate: "2023-12-31",
                  eventStatus: "https://schema.org/EventScheduled",
                  eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                  location: {
                    "@type": "Place",
                    name: "Кэшбэк до 10%",
                    address: {
                      "@type": "PostalAddress",
                      streetAddress: "Москва",
                      addressLocality: "Москва",
                      postalCode: "123100",
                      addressCountry: "RU",
                    },
                  },
                  performer: {
                    "@type": "PerformingGroup",
                    name: "HalvaCard",
                  },
                  offers: {
                    "@type": "Offer",
                    name: "Кэшбэк до 10%",
                    price: "999",
                    priceCurrency: "RUB",
                    validFrom: "",
                    url: "https://halvacard.ru/order/credit-card/",
                    availability: "https://schema.org/InStock",
                  },
                },
              ])}
            </script>
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  itemListElement: [
                    {
                      "@type": "ListItem",
                      position: 1,
                      name: "Halvacard",
                      item: "https://halvacard.ru/",
                    },
                    {
                      "@type": "ListItem",
                      position: 2,
                      name: "💳 Кредитные карты",
                      item: "https://halvacard.ru/order/credit-card/",
                    },
                    {
                      "@type": "ListItem",
                      position: 3,
                      name: `${shortTitle}`,
                      item: `${url}`,
                    },
                  ],
                },
              ])}
            </script>
          </Helmet>
        )}
      {process.env.GATSBY_PATH_PREFIX === "/order" &&
        url.includes("debet-cards") &&
        url.endsWith("debet-cards/") && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BankOrCreditUnion",
                name: "Halvacard",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                "@id": "https://halvacard.ru",
                url: "https://halvacard.ru/",
                telephone: "8 (800) 100-77-72",
                priceRange: "10 000 - 500 000",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: "Краснопресненская набережная, 14 стр 1",
                  addressLocality: "Москва",
                  postalCode: "123100",
                  addressCountry: "RU",
                },
                openingHoursSpecification: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ],
                  opens: "00:00",
                  closes: "23:59",
                },
                sameAs: [
                  "https://ru.wikipedia.org/wiki/Совкомбанк",
                  "https://halvacard.ru",
                  "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                ],
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                name: titleH1,
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                description,
                brand: {
                  "@type": "Brand",
                  name: "Halvacard",
                },
                offers: {
                  "@type": "AggregateOffer",
                  url,
                  priceCurrency: "RUB",
                  lowPrice: "10000",
                  highPrice: "500000",
                },
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: "4,9",
                  bestRating: "5",
                  worstRating: "1",
                  ratingCount: "7536",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "SaleEvent",
                name: "📦 Бесплатная доставка",
                description:
                  "Бесплатная доставка карты рассрочки Халва Совкомбанка. Одобрение онлайн. Заполните заявку - и уже завтра курьер доставит вам Халву!",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                startDate: "2023-01-01",
                endDate: "2023-12-31",
                eventStatus: "https://schema.org/EventScheduled",
                eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                location: {
                  "@type": "Place",
                  name: "Бесплатная доставка",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Москва",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                },
                performer: {
                  "@type": "PerformingGroup",
                  name: "HalvaCard",
                },
                offers: {
                  "@type": "Offer",
                  name: "Бесплатная доставка",
                  price: "999",
                  priceCurrency: "RUB",
                  validFrom: "",
                  url: "https://halvacard.ru/order/debet-cards/",
                  availability: "https://schema.org/InStock",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "SaleEvent",
                name: "💳 До 10 месяцев рассрочки",
                description:
                  "Получить карту Халва с рассрочкой 10 месяцев очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                startDate: "2023-01-01",
                endDate: "2023-12-31",
                eventStatus: "https://schema.org/EventScheduled",
                eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                location: {
                  "@type": "Place",
                  name: "До 10 месяцев рассрочки",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Москва",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                },
                performer: {
                  "@type": "PerformingGroup",
                  name: "HalvaCard",
                },
                offers: {
                  "@type": "Offer",
                  name: "До 10 месяцев рассрочки",
                  price: "999",
                  priceCurrency: "RUB",
                  validFrom: "",
                  url: "https://halvacard.ru/order/debet-cards/",
                  availability: "https://schema.org/InStock",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "SaleEvent",
                name: "🪙 Кэшбэк до 10%",
                description:
                  "Получить карту рассрочки Халва с кэшбэком до 10% очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                startDate: "2023-01-01",
                endDate: "2023-12-31",
                eventStatus: "https://schema.org/EventScheduled",
                eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                location: {
                  "@type": "Place",
                  name: "Кэшбэк до 10%",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Москва",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                },
                performer: {
                  "@type": "PerformingGroup",
                  name: "HalvaCard",
                },
                offers: {
                  "@type": "Offer",
                  name: "Кэшбэк до 10%",
                  price: "999",
                  priceCurrency: "RUB",
                  validFrom: "",
                  url: "https://halvacard.ru/order/debet-cards/",
                  availability: "https://schema.org/InStock",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Halvacard",
                    item: "https://halvacard.ru/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "💳 Дебетовые карты",
                    item: "https://halvacard.ru/order/debet-cards/",
                  },
                ],
              })}
            </script>
          </Helmet>
        )}
      {process.env.GATSBY_PATH_PREFIX === "/order" &&
        url.includes("debet-cards") &&
        !url.endsWith("debet-cards/") && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BankOrCreditUnion",
                name: "Halvacard",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                "@id": "https://halvacard.ru",
                url: "https://halvacard.ru/",
                telephone: "8 (800) 100-77-72",
                priceRange: "10 000 - 500 000",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: "Краснопресненская набережная, 14 стр 1",
                  addressLocality: "Москва",
                  postalCode: "123100",
                  addressCountry: "RU",
                },
                openingHoursSpecification: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ],
                  opens: "00:00",
                  closes: "23:59",
                },
                sameAs: [
                  "https://ru.wikipedia.org/wiki/Совкомбанк",
                  "https://halvacard.ru",
                  "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                ],
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                name: titleH1,
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                description,
                brand: {
                  "@type": "Brand",
                  name: "Halvacard",
                },
                offers: {
                  "@type": "AggregateOffer",
                  url,
                  priceCurrency: "RUB",
                  lowPrice: "10000",
                  highPrice: "500000",
                },
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: "4,9",
                  bestRating: "5",
                  worstRating: "1",
                  ratingCount: "7536",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "SaleEvent",
                name: "📦 Бесплатная доставка",
                description:
                  "Бесплатная доставка карты рассрочки Халва Совкомбанка. Одобрение онлайн. Заполните заявку - и уже завтра курьер доставит вам Халву!",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                startDate: "2023-01-01",
                endDate: "2023-12-31",
                eventStatus: "https://schema.org/EventScheduled",
                eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                location: {
                  "@type": "Place",
                  name: "Бесплатная доставка",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Москва",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                },
                performer: {
                  "@type": "PerformingGroup",
                  name: "HalvaCard",
                },
                offers: {
                  "@type": "Offer",
                  name: "Бесплатная доставка",
                  price: "999",
                  priceCurrency: "RUB",
                  validFrom: "",
                  url: "https://halvacard.ru/order/debet-cards/",
                  availability: "https://schema.org/InStock",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "SaleEvent",
                name: "💳 До 10 месяцев рассрочки",
                description:
                  "Получить карту Халва с рассрочкой 10 месяцев очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                startDate: "2023-01-01",
                endDate: "2023-12-31",
                eventStatus: "https://schema.org/EventScheduled",
                eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                location: {
                  "@type": "Place",
                  name: "До 10 месяцев рассрочки",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Москва",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                },
                performer: {
                  "@type": "PerformingGroup",
                  name: "HalvaCard",
                },
                offers: {
                  "@type": "Offer",
                  name: "До 10 месяцев рассрочки",
                  price: "999",
                  priceCurrency: "RUB",
                  validFrom: "",
                  url: "https://halvacard.ru/order/debet-cards/",
                  availability: "https://schema.org/InStock",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "SaleEvent",
                name: "🪙 Кэшбэк до 10%",
                description:
                  "Получить карту рассрочки Халва с кэшбэком до 10% очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                startDate: "2023-01-01",
                endDate: "2023-12-31",
                eventStatus: "https://schema.org/EventScheduled",
                eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                location: {
                  "@type": "Place",
                  name: "Кэшбэк до 10%",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "Москва",
                    addressLocality: "Москва",
                    postalCode: "123100",
                    addressCountry: "RU",
                  },
                },
                performer: {
                  "@type": "PerformingGroup",
                  name: "HalvaCard",
                },
                offers: {
                  "@type": "Offer",
                  name: "Кэшбэк до 10%",
                  price: "999",
                  priceCurrency: "RUB",
                  validFrom: "",
                  url: "https://halvacard.ru/order/debet-cards/",
                  availability: "https://schema.org/InStock",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Halvacard",
                    item: "https://halvacard.ru/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "💳 Дебетовые карты",
                    item: "https://halvacard.ru/order/debet-cards/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: shortTitle,
                    item: url,
                  },
                ],
              })}
            </script>
          </Helmet>
        )}
      {process.env.GATSBY_PATH_PREFIX === "/order" &&
        url.includes("zaimy") &&
        url.endsWith("zaimy/") && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BankOrCreditUnion",
                name: "Halvacard",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                "@id": "https://halvacard.ru",
                url: "https://halvacard.ru/",
                telephone: "8 (800) 100-77-72",
                priceRange: "10 000 - 500 000",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: "Краснопресненская набережная, 14 стр 1",
                  addressLocality: "Москва",
                  postalCode: "123100",
                  addressCountry: "RU",
                },
                openingHoursSpecification: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ],
                  opens: "00:00",
                  closes: "23:59",
                },
                sameAs: [
                  "https://ru.wikipedia.org/wiki/Совкомбанк",
                  "https://halvacard.ru",
                  "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                ],
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                name: `${titleH1}`,
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                description: `${description}`,
                brand: {
                  "@type": "Brand",
                  name: "Halvacard",
                },
                offers: {
                  "@type": "AggregateOffer",
                  url: "https://halvacard.ru/order/zaimy/",
                  priceCurrency: "RUB",
                  lowPrice: "10000",
                  highPrice: "500000",
                },
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: "4,9",
                  bestRating: "5",
                  worstRating: "1",
                  ratingCount: "7694",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: "https://halvacard.ru/order/zaimy/",
                name: "💸 До 10 месяцев без процентов",
                "@type": "SaleEvent",
                about: "💸 До 10 месяцев без процентов",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: "https://halvacard.ru/order/zaimy/",
                name: "⚡ Моментальное оформление",
                "@type": "SaleEvent",
                about: "⚡ Моментальное оформление",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: "https://halvacard.ru/order/zaimy/",
                name: "💰 Лимит до 500 000 рублей",
                "@type": "SaleEvent",
                about: "💰 Лимит до 500 000 рублей",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Главная",
                    item: "https://halvacard.ru/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "🪙 Займы онлайн",
                    item: "https://halvacard.ru/order/zaimy/",
                  },
                ],
              })}
            </script>
          </Helmet>
        )}
      {process.env.GATSBY_PATH_PREFIX === "/order" &&
        url.includes("zaimy") &&
        !url.endsWith("zaimy/") && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BankOrCreditUnion",
                name: "Halvacard",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                "@id": "https://halvacard.ru",
                url: "https://halvacard.ru/",
                telephone: "8 (800) 100-77-72",
                priceRange: "10 000 - 500 000",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: "Краснопресненская набережная, 14 стр 1",
                  addressLocality: "Москва",
                  postalCode: "123100",
                  addressCountry: "RU",
                },
                openingHoursSpecification: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ],
                  opens: "00:00",
                  closes: "23:59",
                },
                sameAs: [
                  "https://ru.wikipedia.org/wiki/Совкомбанк",
                  "https://halvacard.ru",
                  "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                ],
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                name: `${titleH1}`,
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                description: `${description}`,
                brand: {
                  "@type": "Brand",
                  name: "Halvacard",
                },
                offers: {
                  "@type": "AggregateOffer",
                  url: `${url}`,
                  priceCurrency: "RUB",
                  lowPrice: "10000",
                  highPrice: "500000",
                },
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: "4,9",
                  bestRating: "5",
                  worstRating: "1",
                  ratingCount: "7694",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: `${url}`,
                name: "💸 До 10 месяцев без процентов",
                "@type": "SaleEvent",
                about: "💸 До 10 месяцев без процентов",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: `${url}`,
                name: "⚡ Моментальное оформление",
                "@type": "SaleEvent",
                about: "⚡ Моментальное оформление",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: `${url}`,
                name: "💰 Лимит до 500 000 рублей",
                "@type": "SaleEvent",
                about: "💰 Лимит до 500 000 рублей",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Главная",
                    item: "https://halvacard.ru/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "💳Займы онлайн",
                    item: "https://halvacard.ru/order/zaimy/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `${shortTitle}`,
                    item: `${url}`,
                  },
                ],
              })}
            </script>
          </Helmet>
        )}
      {process.env.GATSBY_PATH_PREFIX === "/order" &&
        url.includes("halva/refinansirovanie-kreditnoy-karty") && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BankOrCreditUnion",
                name: "Halvacard",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                "@id": "https://halvacard.ru",
                url: "https://halvacard.ru/",
                telephone: "8 (800) 100-77-72",
                priceRange: "10 000 - 500 000",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: "Краснопресненская набережная, 14 стр 1",
                  addressLocality: "Москва",
                  postalCode: "123100",
                  addressCountry: "RU",
                },
                openingHoursSpecification: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ],
                  opens: "00:00",
                  closes: "23:59",
                },
                sameAs: [
                  "https://ru.wikipedia.org/wiki/Совкомбанк",
                  "https://halvacard.ru",
                  "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                ],
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                name: `${titleH1}`,
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                description: `${description}`,
                brand: {
                  "@type": "Brand",
                  name: "Halvacard",
                },
                offers: {
                  "@type": "AggregateOffer",
                  url: "https://halvacard.ru/order/halva/refinansirovanie-kreditnoy-karty/",
                  priceCurrency: "RUB",
                  lowPrice: "1000",
                  highPrice: "300000",
                },
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: "4,9",
                  bestRating: "5",
                  worstRating: "1",
                  ratingCount: "7568",
                },
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: "https://halvacard.ru/order/halva/refinansirovanie-kreditnoy-karty/",
                name: "💸 До 24 месяцев без процентов",
                description:
                  "Получить карту Халва с возможностью рефинансирования до 24 месяцев без процентов очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                "@type": "SaleEvent",
                about: "💸 До 24 месяцев без процентов",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: "https://halvacard.ru/order/halva/refinansirovanie-kreditnoy-karty/",
                name: "⚡ Моментальное оформление",
                description:
                  "Одобрение онлайн. Заполните заявку - и уже завтра курьер доставит вам Халву!",
                "@type": "SaleEvent",
                about: "⚡ Моментальное оформление",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                url: "https://halvacard.ru/order/halva/refinansirovanie-kreditnoy-karty/",
                name: "💰 До 300 000 рублей",
                description:
                  "Получить карту рассрочки Халва с лимитом на рефинансирование кредитных карт до 300 000 рублей легко! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                "@type": "SaleEvent",
                about: "💰  До 300 000 рублей",
                image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                location: {
                  name: "Halvacard",
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                  },
                },
                organizer: "Halvacard",
                endDate: "2023-12-31T03:00:00+00:00",
                startDate: "2023-01-01T03:00:00+00:00",
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Главная",
                    item: "https://halvacard.ru/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "💳 Рефинансирование кредитных карт",
                    item: "https://halvacard.ru/order/halva/refinansirovanie-kreditnoy-karty/",
                  },
                ],
              })}
            </script>
          </Helmet>
        )}

      {process.env.GATSBY_PATH_PREFIX === "/order" && (
        <>
          {seoProductsCategoriesUrls.map(
            (currentUrl: string) =>
              url?.includes(currentUrl) && (
                <Helmet>
                  <script type="application/ld+json">
                    {JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "BankOrCreditUnion",
                      name: "Halvacard",
                      image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                      "@id": "https://halvacard.ru",
                      url: "https://halvacard.ru/",
                      telephone: "8 (800) 100-77-72",
                      priceRange: "10 000 - 500 000",
                      address: {
                        "@type": "PostalAddress",
                        streetAddress: "Краснопресненская набережная, 14 стр 1",
                        addressLocality: "Москва",
                        postalCode: "123100",
                        addressCountry: "RU",
                      },
                      openingHoursSpecification: {
                        "@type": "OpeningHoursSpecification",
                        dayOfWeek: [
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                          "Sunday",
                        ],
                        opens: "00:00",
                        closes: "23:59",
                      },
                      sameAs: [
                        "https://ru.wikipedia.org/wiki/Совкомбанк",
                        "https://halvacard.ru",
                        "https://www.youtube.com/channel/UCptG0e45r5jZexlXJ_obmvA",
                      ],
                    })}
                  </script>

                  <script type="application/ld+json">
                    {JSON.stringify({
                      "@context": "https://schema.org/",
                      "@type": "Product",
                      name: `${titleH1}`,

                      image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                      description: `${description}`,
                      brand: {
                        "@type": "Brand",
                        name: "Halvacard",
                      },
                      offers: {
                        "@type": "AggregateOffer",
                        url: `${url}`,
                        priceCurrency: "RUB",
                        lowPrice: "10000",
                        highPrice: "500000",
                      },
                      aggregateRating: {
                        "@type": "AggregateRating",
                        ratingValue: "9,4",
                        bestRating: "10",
                        worstRating: "1",
                        ratingCount: "14594",
                      },
                    })}
                  </script>

                  <script type="application/ld+json">
                    {JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "SaleEvent",
                      name: "📦 Бесплатная доставка",
                      description:
                        "Бесплатная доставка карты рассрочки Халва Совкомбанка. Одобрение онлайн. Заполните заявку - и уже завтра курьер доставит вам Халву!",
                      image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                      startDate: "2023-01-01",
                      endDate: "2025-12-31",
                      eventStatus: "https://schema.org/EventScheduled",
                      eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                      location: {
                        "@type": "Place",
                        name: "Бесплатная доставка",
                        address: {
                          "@type": "PostalAddress",
                          streetAddress: "Москва",
                          addressLocality: "Москва",
                          postalCode: "123100",
                          addressCountry: "RU",
                        },
                      },
                      performer: {
                        "@type": "PerformingGroup",
                        name: "HalvaCard",
                      },
                      offers: {
                        "@type": "Offer",
                        name: "Бесплатная доставка",
                        price: "999",
                        priceCurrency: "RUB",
                        validFrom: "",
                        url: "https://halvacard.ru/cards/main/halva",
                        availability: "https://schema.org/InStock",
                      },
                    })}
                  </script>

                  <script type="application/ld+json">
                    {JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "SaleEvent",
                      name: "💳 До 10 месяцев рассрочки",
                      description:
                        "Получить карту Халва с рассрочкой 10 месяцев очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                      image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                      startDate: "2023-01-01",
                      endDate: "2025-12-31",
                      eventStatus: "https://schema.org/EventScheduled",
                      eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                      location: {
                        "@type": "Place",
                        name: "До 10 месяцев рассрочки",
                        address: {
                          "@type": "PostalAddress",
                          streetAddress: "Москва",
                          addressLocality: "Москва",
                          postalCode: "123100",
                          addressCountry: "RU",
                        },
                      },
                      performer: {
                        "@type": "PerformingGroup",
                        name: "HalvaCard",
                      },
                      offers: {
                        "@type": "Offer",
                        name: "До 10 месяцев рассрочки",
                        price: "999",
                        priceCurrency: "RUB",
                        validFrom: "",
                        url: "https://halvacard.ru/cards/main/halva",
                        availability: "https://schema.org/InStock",
                      },
                    })}
                  </script>

                  <script type="application/ld+json">
                    {JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "SaleEvent",
                      name: "🪙 Кэшбэк до 10%",
                      description:
                        "Получить карту рассрочки Халва с кэшбэком до 10% очень просто! Оставьте заявку на сайте, или приходите в отделение Совкомбанка.",
                      image: "https://s3.halvacard.ru/halvacardrucdn/img/cck/halva-mir-desktop.png",
                      startDate: "2023-01-01",
                      endDate: "2025-12-31",
                      eventStatus: "https://schema.org/EventScheduled",
                      eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
                      location: {
                        "@type": "Place",
                        name: "Кэшбэк до 10%",
                        address: {
                          "@type": "PostalAddress",
                          streetAddress: "Москва",
                          addressLocality: "Москва",
                          postalCode: "123100",
                          addressCountry: "RU",
                        },
                      },
                      performer: {
                        "@type": "PerformingGroup",
                        name: "HalvaCard",
                      },
                      offers: {
                        "@type": "Offer",
                        name: "Кэшбэк до 10%",
                        price: "999",
                        priceCurrency: "RUB",
                        validFrom: "",
                        url: "https://halvacard.ru/cards/main/halva",
                        availability: "https://schema.org/InStock",
                      },
                    })}
                  </script>

                  <script type="application/ld+json">
                    {JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "BreadcrumbList",
                      itemListElement: [
                        {
                          "@type": "ListItem",
                          position: 1,
                          name: "Halvacard",
                          item: "https://halvacard.ru/",
                        },
                        {
                          "@type": "ListItem",
                          position: 2,
                          name: "💳 Карта рассрочки",
                          item: "https://halvacard.ru/cards/main/halva",
                        },
                        {
                          "@type": "ListItem",
                          position: 3,
                          name: `${shortTitle}`,
                          item: `${url}`,
                        },
                      ],
                    })}
                  </script>
                </Helmet>
              )
          )}
        </>
      )}
    </>
  )
}
